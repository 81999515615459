import React from "react";

import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { UseWalletProvider } from "use-wallet";

import "./index.css";

import App from "./App";
import reportWebVitals from "./reportWebVitals";
import useChainId from "./hooks/useChainId";
import { getRpcUrl } from "./components/constants";

const UseWalletProviderWrapper = (props) => {
  const chainId = useChainId();

  return (
    <UseWalletProvider
      chainId={chainId}
      connectors={{
        // This is how connectors get configured
        walletconnect: {
          rpc: {
            56: getRpcUrl(56),
            43114: getRpcUrl(43114),
            137: getRpcUrl(137),
          },
        },
      }}
      {...props}
    ></UseWalletProvider>
  );
};

ReactDOM.render(
  <React.StrictMode>
    <UseWalletProviderWrapper>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </UseWalletProviderWrapper>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
