import { useState } from "react";
import { utils } from "ethers";

export function useWalletInfoAirdrop(data) {
  const { contract, merkle } = data;

  const [total, setTotal] = useState(0);
  const [bin, setBin] = useState(null);
  const [isClaimed, setIsClaimed] = useState(false);
  const [loading, setLoading] = useState(false);

  async function readWalletData(address) {
    const merklebin = merkle.bins[address];
    if (!merklebin) {
      setLoading(false);
      return;
    }

    const isAirdropClaimed = await contract.isClaimed(merklebin.index);

    setBin(merklebin);

    setTotal(Number(utils.formatEther(merklebin.amount)));

    setIsClaimed(isAirdropClaimed);
    setLoading(false);
  }

  function clearWalletData() {
    setTotal(0);
    setIsClaimed(0);
    setBin(null);
    setLoading(false);
  }

  return {
    setLoading,
    readWalletData,
    clearWalletData,
    total,
    bin,
    isClaimed,
    loading
  };
}
