import React from "react";

// export interface ExternalLinkProps {
//   href: string;
//   children?: ReactNode;
//   [rest: string]: any;
// }

const ExternalLink = ({ href, children, ...rest }) => (
  <a target="_blank" rel="noopener noreferrer" href={href} {...rest}>
    {children ?? href}
  </a>
);

export default ExternalLink;
