import React from "react";

import { useWallet } from "use-wallet";

import ExternalLink from "../components/ExternalLink/ExternalLink";
import { shortenAddress } from "../components/shortenAddress";
import { getChainColer, getChainName } from "../components/constants";

import LPIlogo from "../assets/lpilogocolor.png";

function Topbar({ openModal }) {
  const wallet = useWallet();
  return (
    <div className="topbar-container">
      <div className="topbar-box-left">
        <ExternalLink href="https://lpi.finance">
          <img src={LPIlogo} alt="LPI DAO" className="logo" />
        </ExternalLink>
      </div>

      <div className="topbar-box-center">LPI Vesting Portal</div>

      <div className="topbar-box-right">
        {wallet.status === "connected" ? (
          <div>
            <button>
              {wallet.account ? shortenAddress(wallet.account) : ""}
            </button>
            {/* <div>Balance: {wallet.balance}</div> */}
            <button onClick={() => wallet.reset()} variant="outline-primary">
              Disconnect
            </button>

            <span
              style={{
                fontSize: "20px",
                padding: "5px",
                color: getChainColer(wallet.chainId),
              }}
            >
              {getChainName(wallet.chainId)}
            </span>
          </div>
        ) : (
          <div>
            <button onClick={openModal} variant="primary">
              Connect
            </button>
          </div>
        )}
      </div>
    </div>
  );
}

export default Topbar;
