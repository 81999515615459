// import React from "react";
import Modal from "react-modal";

import "./ConnectWalletModal.css";

import { useWallet } from "use-wallet";

import metamaskLogo from "../../assets/wallets/metamask.svg";
import walletconnectLogo from "../../assets/wallets/walletconnect.svg";

// TODO: Needs a LederDerivationPathModal

Modal.setAppElement("#root");

function ConnectWalletModal({ modalIsOpen, setIsOpen }) {
  const wallet = useWallet();

  // function handleConnectorSelect(connector) {
  //   if (wallet.isActive) {
  //     return;
  //   }

  //   // TOOD: Handle ledger

  //   return wallet.connect(connector);
  // }

  function closeModal() {
    setIsOpen(false);
  }

  const handleMetamask = () => {
    wallet.connect();
    // console.log("status", ChainUnsupportedError);
    if (wallet.status === "connected") {
      closeModal();
    }
  };

  return (
    <Modal
      className="connect-modal"
      isOpen={modalIsOpen}
      onRequestClose={closeModal}
    >
      <h2>Connect Wallet</h2>
      <p>Please select your wallet</p>

      <div className="wallet-options-container">
        <button className="wallet-option" onClick={() => handleMetamask()}>
          <img src={metamaskLogo} alt="Metamask logo" />
          <span>Metamask</span>
        </button>
        <button
          className="wallet-option"
          onClick={() => wallet.connect("walletconnect")}
        >
          <img src={walletconnectLogo} alt="WalletConnect logo" />
          <span>WalletConnect</span>
        </button>
      </div>

      {/* <div className="wallet-options-container">
        {WalletConnectors.map((connector, idx) => (
          <button
            key={idx}
            className="wallet-option"
            onClick={() => handleConnectorSelect(connector)}
          >
            <img src={connector.logo} alt={connector.name} />
            <span>{connector.name}</span>
          </button>
        ))}
      </div> */}
    </Modal>
  );
}

export default ConnectWalletModal;
