import { utils } from "ethers"

export function shortenString(str) {
    return str.substring(0, 6) + '...' + str.substring(str.length - 4)
  }
  
export function shortenAddress(address) {
    try {
      const formattedAddress = utils.getAddress(address)
      return shortenString(formattedAddress)
    } catch {
      throw new TypeError("Invalid input, address can't be parsed")
    }
  }