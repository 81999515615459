import { useCallback, useEffect, useState } from "react";

const useChainId = () => {
  const [chainId, setChainId] = useState(56);

  const fetchChainId = useCallback(async () => {
    if (window.ethereum) {
      const ethereum = window.ethereum;
      let chainId = await ethereum.request({
        method: "eth_chainId",
      });
      chainId = parseInt(chainId, 16);
      console.log("network changed");

      setChainId(chainId);

      // window.ethereum.on("networkChanged", function (chainId) {
      //   chainId = parseInt(chainId, 16);
      //   console.log("network changed");
      //   setChainId(chainId);
      // });
    }
  }, [setChainId]);

  useEffect(() => {
    fetchChainId().catch((err) => console.error(err.stack));

    window.ethereum.on("networkChanged", fetchChainId);

    // const refreshChainId = setInterval(fetchChainId, 1000);
    // return () => clearInterval(refreshChainId);

    return () => window.ethereum.removeListener("networkChanged", fetchChainId);
  }, [fetchChainId]);

  return chainId;
};

export default useChainId;
