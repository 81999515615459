import React from "react";

import { ethers } from "ethers";
import { shortenString } from "../components/shortenAddress";
import { useWallet } from "use-wallet";
import { getExplorerLink } from "../components/constants";
import { toast } from "react-toastify";

function useTransact() {
  const wallet = useWallet();

  const explorer = getExplorerLink(wallet.chainId);

  const ToastExplorer = hash => (
    <div>
      <a href={explorer + hash} target="_blank" rel="noreferrer">
        Transaction pending: {shortenString(hash)}
      </a>
    </div>
  );

  async function claim(contractAdd, bin) {
    if (wallet.status === "connected") {
      const provider = new ethers.providers.Web3Provider(
        wallet._web3ReactContext.library
      );
      // console.log(wallet);
      // console.log(window.ethereum);
      // console.log(wallet._web3ReactContext.library);
      // if (wallet.connector === "injected") {
      //   await window.ethereum.enable();
      //   provider = new ethers.providers.Web3Provider(
      //     wallet._web3ReactContext.library
      //   );
      //   console.log("injected working", provider);
      // }

      // if (wallet.connector === "walletconnect") {
      //   provider = new ethers.providers.Web3Provider(
      //     wallet._web3ReactContext.library
      //   );
      //   console.log("walletconnect working", provider);
      // }

      // const provider = rpcProvider;
      // console.log(wallet.connectors);
      // const strAmount = amount.toString();
      // console.log(utils.parseEther(strAmount));

      const signer = provider.getSigner();
      // const address = await signer.getAddress();
      // console.log(address);
      const contractwithsigner = contractAdd.connect(signer);
      try {
        let tx;
        if (bin) {
          tx = await contractwithsigner.claim(
            bin.index,
            wallet.account,
            ethers.utils.formatUnits(bin.amount, 0),
            bin.proof
          );
        } else {
          tx = await contractwithsigner.claim(wallet.account);
        }

        toast.info(ToastExplorer(tx.hash), {
          closeOnClick: false
        });
        // console.log("tx", tx);
        await tx.wait();
        toast.success(`Transaction Successful`);
        // console.log("receipt", await receipt);
      } catch (e) {
        // console.log("err", e);
        // console.log("err.msg", e.message);

        if (e.hasOwnProperty("error")) {
          toast.error(`${e.error.message}`);
          return;
        } else if (e.hasOwnProperty("message")) {
          toast.error(`${e.message}`);
          return;
        } else toast.error(`${e}`);
        return;
      }

      // signer.sendTransaction({
      //     to: "ricmoo.firefly.eth",
      //     value: ethers.utils.parseEther("0.1")
      // }
      // );
    }
  }

  return [claim];
}

export default useTransact;
