import React from "react";
import { NavLink } from "react-router-dom";

import shoefiLogo from "../assets/projects/shoefi.png";
import LPIprojectLogo from "../assets/projects/lpiproject.png";
import satoshicityLogo from "../assets/projects/satoshicity.png";
import doragonLogo from "../assets/projects/doragon.png";
import duckieLogo from "../assets/projects/duckie.png";
import boldpointLogo from "../assets/projects/boldpoint.png";
import synergylandLogo from "../assets/projects/synergyland.png";
import qorpoLogo from "../assets/projects/qorpo.png";
import unboundLogo from "../assets/projects/unbound.png";
import lyberLogo from "../assets/projects/lyber.png";
import litcraftLogo from "../assets/projects/litcraft.jpg";

// ADD MORE!!

function ProjectList() {
  return (
    <div className="projects">
      <NavLink to="/lpi">
        <img src={LPIprojectLogo} alt="lpi logo" className="project-logo" />
      </NavLink>

      <NavLink to="/shoe">
        <img src={shoefiLogo} alt="shoefy logo" className="project-logo" />
      </NavLink>

      <NavLink to="/satoshicity">
        <img
          src={satoshicityLogo}
          alt="satoshi city logo"
          className="project-logo"
        />
      </NavLink>

      <NavLink to="/doragonland">
        <img src={doragonLogo} alt="doragon logo" className="project-logo" />
      </NavLink>

      <NavLink to="/duckieland">
        <img src={duckieLogo} alt="duckie logo" className="project-logo" />
      </NavLink>

      <NavLink to="/boldpoint">
        <img
          src={boldpointLogo}
          alt="bold point logo"
          className="project-logo"
        />
      </NavLink>

      <NavLink to="/synergyland">
        <img
          src={synergylandLogo}
          alt="synergy land logo"
          className="project-logo"
        />
      </NavLink>

      <NavLink to="/qorpo">
        <img src={qorpoLogo} alt="qorpo logo" className="project-logo" />
      </NavLink>

      <NavLink to="/unbound">
        <img src={unboundLogo} alt="unbound logo" className="project-logo" />
      </NavLink>

      <NavLink to="/lyber">
        <img src={lyberLogo} alt="lyber logo" className="project-logo" />
      </NavLink>

      <NavLink to="/litcraft">
        <img src={litcraftLogo} alt="litcraft logo" className="project-logo" />
      </NavLink>
      {/* ADD MORE */}
    </div>
  );
}

export default ProjectList;
