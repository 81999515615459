import { useState } from "react";

import { utils } from "ethers";

export function useWalletInfoVesting(contractData) {
  const [claimable, setClaimable] = useState(0);
  // const [claiminfo, setClaiminfo] = useState();
  const [total, setTotal] = useState(0);
  const [startTime, setStartTime] = useState();
  const [endTime, setEndTime] = useState();
  // const [lastClaimTime, setLastClaimTime] = useState();
  const [claimed, setClaimed] = useState(0);
  const [loading, setLoading] = useState(false);

  async function readWalletData(address) {
    const claimabledata = await contractData.claimableAmount(address);
    const claiminfodata = await contractData.getClaim(address);

    // console.log(utils.formatEther(claimabledata), "claimable");
    // console.log(claiminfodata, "claiminfo");

    // console.log(utils.formatEther(claiminfodata[0]), "total");
    // console.log(String(claiminfodata[1]), "starttime");
    // console.log(String(claiminfodata[2]), "endtime");
    // console.log(String(claiminfodata[3]), "lastclaimtime");
    // console.log(utils.formatEther(claiminfodata[4]), "claimed");

    // console.log(address, "addy");

    // console.log("woorking", whitelisted);
    setClaimable(Number(utils.formatEther(claimabledata)));
    // setClaiminfo(claiminfodata);
    setTotal(Number(utils.formatEther(claiminfodata[0])));
    setStartTime(String(claiminfodata[1]));
    setEndTime(String(claiminfodata[2]));
    // setLastClaimTime(String(claiminfodata[3]));
    setClaimed(Number(utils.formatEther(claiminfodata[4])));
    setLoading(false);
  }

  function clearWalletData() {
    setClaimable(0);
    // setClaiminfo();
    setTotal(0);
    setStartTime(0);
    setEndTime(0);
    // setLastClaimTime();
    setClaimed(0);
    setLoading(false);
  }

  return {
    setLoading,
    readWalletData,
    clearWalletData,
    claimable,
    // claiminfo,
    total,
    startTime,
    endTime,
    // lastClaimTime,
    claimed,
    loading
  };
}
